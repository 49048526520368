import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import mbplayer from "mb-player";
import Vuei18n from "vue-i18n";
import VueRouter from "vue-router";

Vue.config.productionTip = false;
Vue.use(mbplayer);
Vue.use(Vuei18n);
Vue.use(VueRouter);

const i18n = new Vuei18n({
  locale: "en",
  messages: {
    id: require("./lang/id.json"),
    en: require("./lang/en.json"),
  },
});
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/:locale",
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.params.locale) {
    i18n.locale = to.params.locale;
  }
  next();
});
new Vue({
  vuetify,
  render: (h) => h(App),
  router,
  i18n,
}).$mount("#app");
