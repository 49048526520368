<template>
  <div id="app">
    <div class="text-right">
      <!-- <span>Language: </span>
      <select v-model="$i18n.locale" style="padding: 0px 8px;margin: 8px 4px;border: 1px solid;border-radius: 8px;">
        <option value="en">English</option>
        <option value="id">Bahasa</option>
      </select> -->
    </div>
    <img :src="require(`./assets/20240622/${$i18n.locale}/TieryHenry.png`)" class="mx-auto block" width="83%" alt="">
    <div class="text-center mt-5">
      GBall App<br />
      Olahraga, Live Kasino, Slot<br />
      Android | Versi 2.1.0 | 52 MB
    </div>
    <div class="text-center">
      <button class="download-btn py-2 px-10 mt-3" @click="clickDownload">
        {{ $t('download') }}
      </button>
    </div>
    <div class="mt-3">
      <img width="100%" :src="require(`./assets/20240622/${$i18n.locale}/hr.png`)" alt="">
    </div>
    <div class="flex flex-col gap-[107px] mt-15">
      <div class="text-center" v-for="(i, index) in [1, 2, 3, 4, 5]" :key="index">
        <img width="68%" :src="require(`./assets/20240622/${$i18n.locale}/Step${i}.png`)" alt="">
      </div>
    </div>
    <div class="w-[68%] mx-auto text-center text-sm">{{ $t('still-having-problem') }}<br /> {{
      $t('contact-our-live-cs-for-more-information') }}</div>
    <router-view />
  </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
  name: 'App',
  data() {
    return {
      mobile: !(window.innerWidth > 900),
      qrImg: null,
      rating: 4.5,
      timeout: localStorage.getItem('timeout') === 'true',
      pauseTimer: null,
      apkUrl: 'https://static.demoapi88.com/apps/gballvip_2.10_0701.apk',
      dlUrl: 'https://m.goal158.com',
      showTip: false,
      ios: false,
      iosDownloadDialog: false,
      fromMobile: false,
      iosRedirectUrl: [
        'jinqiu111.com',
        'jinqiu444.com',
        'jinqiu555.com',
        'jinqiu777.com',
        'jinqiu999.com',
        'jqty11.com',
        'jqty44.com',
        'jqty55.com',
        'jqty77.com',
        'jqty99.com'
      ],
      allLang: {
        'cn': [
          '8秒极速下载',
          'APP 加载中...',
          '首次打开app时',
          '请',
          '【双击】',
          '下方',
          '【粉红色按钮】',
          '前往下载 数数乐',
          '看 好 球 行 好 运',
          '进入金球体育',
          '下载APP遇到问题? 您可以谘询:',
          '扫码下载金球体育',
          '金球体育永久下载地址',
          '158万次下载',
          '或',
          '一键收藏金球体育网址',
          '2021 金球体育 版权所有. All rights Reserved',
          '数数乐',
          '金球体育',
          '向下滑动查看安装教程',
          '前往下载 数数乐',
          '点击',
          '按钮后,再点击',
          '下载',
          '下载成功后,点击',
          '打开',
          '后进行安装',
          '完成安装,可点击',
          '打开',
          '按钮进入APP',
          '前往苹果商店搜寻',
          '数宝 happy maths',
          '下载后打开',
          '点击右上角按钮',
          '选择从浏览器打开',
          '试看结束',
          '8秒极速下载APP 高清畅玩',
          '在线客服'
        ],
        'en': [
          '8 Sec to download',
          'App is loading',
          'First time open app,',
          'please',
          '[Double Click]',
          'the button below',
          '[Pink Button]',
          'Go to download',
          'Good ball, Good Luck',
          'Go to web version',
          'Cannot download, please talk to our online agent',
          'Scan to download',
          'Perminant downloading page',
          'Over 1.58million App Users',
          'Or',
          'Click to save the address',
          '2021 Gball  All rights Reserved',
          'Happy Maths',
          'Gball',
          'Page down to check the install guide',
          'Click to download',
          'Click',
          'Button and Click',
          'Download',
          'Click after downloaded',
          'Open',
          'Install',
          'Click after install',
          'Click',
          'Button to  Start',
          'Search in App Store',
          'Happy Maths',
          'Download and open',
          'Click the up right button ',
          'Open in browser',
          'Trial viewing concluded',
          '8-second rapid app download for HD smooth gaming',
          'live chat'
        ],
        'id': [
          '8 detik untuk download',
          'Aplikasi sedang dibuka...',
          'Saat pertama kali membuka aplikasi',
          'Silakan',
          '[klik tombol]',
          'tombol dibawah',
          '[tombol merah muda]',
          'Ke versi download',
          'Good ball, Good luck',
          'Ke versi web',
          'Download gagal, silakan hubungi live chat',
          'Scan untuk download',
          'Link alternatif untuk download',
          'Lebih dari 1.57 juta Pengguna',
          'Atau',
          'Klik untuk menyimpan alamat',
          '2021 Gball Hak Cipta Dilindungi',
          'Happy Maths',
          'Gball',
          'Lihat kebawah untuk arahan cara instal',
          'Klik untuk download',
          'Klik ',
          'Tombol dan Klik',
          'Download',
          'Klik setelah Download',
          'Buka di browser',
          'Instal',
          'Klik setelah instal',
          'Klik ',
          'Tombol untuk Memulai',
          'Cari di App Store',
          'Happy Maths',
          'Download dan buka',
          'Klik di tombol kanan atas',
          'Buka di browser',
          'Adegan percobaan berakhir',
          'Unduh aplikasi dalam 8 detik untuk bermain dengan lancar dalam definisi tinggi',
          'live chat'
        ]
      },
      selectedLang: 'cn',
      selectedLangData: {
        'name': '中国',
        'value': '简体中文'
      },
      languageData: [
        {
          'img': 'cn',
          'cn': '中国',
          'en': 'China',
          'index': 1,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '512',
              'value': '简体中文',
              'lang': 'zh-CN'
            },
            {
              'id': '',
              'value': '繁体中文',
              'lang': ''
            }
          ]
        },
        {
          'img': 'en',
          'cn': '英国',
          'en': 'UK',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            }
          ]
        },
        {
          'img': 'idn',
          'cn': '印尼',
          'en': 'Indonesia',
          'index': 1,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '7',
              'value': 'Bahasa',
              'lang': 'id-ID'
            }
          ]
        },
        {
          'img': 'hk',
          'cn': '香港',
          'en': 'Hong Kong',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '512',
              'value': '简体中文',
              'lang': 'zh-CN'
            },
            {
              'id': '',
              'value': '繁体中文',
              'lang': ''
            }
          ]
        },
        {
          'img': 'th',
          'cn': '泰国',
          'en': 'Thailang',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'แบบไทย',
              'lang': ''
            }
          ]
        },
        {
          'img': 'ph',
          'cn': '菲律宾',
          'en': 'Philippines',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Tagalog',
              'lang': ''
            }
          ]
        },
        {
          'img': 'my',
          'cn': '马来西亚',
          'en': 'Malaysia',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '512',
              'value': '简体中文',
              'lang': 'zh-CN'
            },
            {
              'id': '',
              'value': 'B. Melayu',
              'lang': ''
            }
          ]
        },
        {
          'img': 'vn',
          'cn': '越南',
          'en': 'Veitnam',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Tiếng Việt',
              'lang': ''
            }
          ]
        },
        {
          'img': 'it',
          'cn': '意大利',
          'en': 'Italy',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Italiano',
              'lang': ''
            }
          ]
        },
        {
          'img': 'gm',
          'cn': '德国',
          'en': 'German',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Deutsch',
              'lang': ''
            }
          ]
        },
        {
          'img': 'fr',
          'cn': '法国',
          'en': 'French',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Français',
              'lang': ''
            }
          ]
        },
        {
          'img': 'au',
          'cn': '澳大利亚',
          'en': 'Australia',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            }
          ]
        },
        {
          'img': 'jp',
          'cn': '日本',
          'en': 'Japan',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': '日本語',
              'lang': ''
            }
          ]
        },
        {
          'img': 'kr',
          'cn': '韩国',
          'en': 'Korea',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': '한국인',
              'lang': ''
            }
          ]
        },
        {
          'img': 'pt',
          'cn': '葡萄牙',
          'en': 'Portugal',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Português',
              'lang': ''
            }
          ]
        },
        {
          'img': 'br',
          'cn': '巴西',
          'en': 'Brasil',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Português',
              'lang': ''
            }
          ]
        },
        {
          'img': 'sd',
          'cn': '瑞典',
          'en': 'Sweden',
          'index': 0,
          'data': [
            {
              'id': '1',
              'value': 'English',
              'lang': 'en-US'
            },
            {
              'id': '',
              'value': 'Svenska',
              'lang': ''
            }
          ]
        }
      ],
      showLang: false
    }
  },
  components: {
  },
  mounted() {
    if (this.isWeixn()) {
      this.showTip = true;
    }
    this.ios = this.isIos() || this.isIpad();


    if (location.href.includes('?from=mobile')) {
      this.fromMobile = true;

      // let num = this.iosRedirectUrl.length;
      // let index = Math.floor(Math.random() * num);

      setTimeout(() => {
        // location.href = this.ios ? `https://${this.iosRedirectUrl[index]}` : this.apkUrl;
        location.href = this.ios ? `https://www.gball.app` : this.apkUrl;
      }, 3000);
    }
    else {
      this.fromMobile = false;
      if (localStorage.getItem('timeout') === 'true') {
        this.pauseTimer = setInterval(() => {
          this.farcePause();
        }, 1000);
      }
      // let currentUrl = location.origin;
      QRCode.toDataURL(`https://www.qiubizhong.com?from=mobile`, { errorCorrectionLevel: 'Q' })
        .then(url => {
          this.qrImg = url
        })
      setTimeout(() => {
        this.timeout = true
        localStorage.setItem('timeout', 'true')
        this.farcePause();
      }, 30000);
    }

    this.selectedLang = localStorage.getItem('lang') || 'cn';
    let name = localStorage.getItem('name') || '中国';
    let value = localStorage.getItem('value') || '简体中文';
    this.selectedLangData = {
      'name': name,
      'value': value
    }
  },
  methods: {
    selectLang(data, id) {
      let dataObj = {
        'name': '',
        'value': ''
      };
      let index = data.data.findIndex(x => x.id === id);
      if (index > -1) {
        dataObj.value = data.data[index].value;
      }

      if (id == 1) {
        this.selectedLang = 'en';
        dataObj.name = data.en;
      }
      else if (id == 512) {
        this.selectedLang = 'cn';
        dataObj.name = data.cn;
      }
      else {
        this.selectedLang = 'id';
        dataObj.name = data.en;
      }

      this.selectedLangData = dataObj;

      localStorage.setItem('lang', this.selectedLang);
      localStorage.setItem('name', dataObj.name);
      localStorage.setItem('value', dataObj.value);
      window.location.reload();
    },
    clickDownload() {
      location.href = this.apkUrl
    },
    farcePause() {
      const player = this.$children.find(component => {
        return Object.prototype.hasOwnProperty.call(component, 'player')
      })
      if (player) {
        if (player.$data && player.$data.player) {
          const video = player.$data.player
          if (!video.paused) {
            video.pause();
          }
        }
      }
    },
    goToG() {
      location.href = 'https://www.jqty88.com/'
    },
    isWeixn() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf('micromessenger') != -1;
      if (isWeixin) {
        return true;
      } else {
        return false;
      }
    },
    isIos() {
      let _uas = navigator.userAgent.toLowerCase();
      if (_uas.match(/iphone/i) || _uas.match(/ipad/i) || _uas.match(/ipod/i)) {
        return true;
      } else {
        return false;
      }
    },
    isIpad() {
      if (/iPad/i.test(navigator.userAgent)) {
        return true;
      }
      if (/Macintosh/i.test(navigator.userAgent)) {
        try {
          document.createEvent('TouchEvent');
          return true;
        } catch (e) {
          //
        }
      }
      return false;
    },
    gameDiary() {
      location.href = 'https://testflight.apple.com/join/XIi6CD9o'
    },
    testFlight() {
      location.href = 'https://apps.apple.com/cn/app/testflight/id899247664'
    },
    trashHelp() {
      // location.href = 'https://apps.apple.com/cn/app/Mr Sewing/id1575216819'
      // location.href = 'https://apps.apple.com/cn/app/mr-sewing/id1587086804?l=zh'
      // location.href = 'https://apps.apple.com/cn/app/id1640282213?l=zh';
      location.href = 'https://apps.apple.com/sg/app/数数乐/id6446477271?l=zh';
    },
    openCs() {
      window.open('https://vue.livelyhelp.chat/ChatWindow.aspx?siteId=60001518&planId=88d43332-b7b8-4ff6-9752-e771a6ab3cb3&pagereferrer=https://gbb38.com', '_blank')
    }
  }
}
</script>

<style lang='scss'>
#app {
  width: 100vw;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 20px 0px;

  &>.lang {
    display: block;
    position: absolute;
    width: 100%;
    height: 28px;
    top: 0;
    left: 0;
    background: #000000;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0px 20px;

    &>.selected {
      display: block;
      position: relative;
      float: right;
      height: 28px;
      line-height: 30px;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  &>.langpop {
    display: block;
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 0px;
    right: 0px;
    height: 100%;

    &>.overlay {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
    }

    &>.cover {
      display: block;
      position: absolute;
      z-index: 1;
      width: 280px;
      top: calc(50% - 160px);
      right: calc(50% - 140px);
      background: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 6px;
      height: 320px;
      overflow-y: auto;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 8px;
      -webkit-border-radius: 8px;

      & .row {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        text-align: left;
        padding: 10px 16px 10px 60px;
        cursor: pointer;
        color: #000;
        font-weight: normal;

        &>img {
          display: block;
          position: absolute;
          width: 36px;
          height: 36px;
          top: calc(50% - 16px);
          left: 10px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          border: solid 1px #000000;
        }

        &>.name {
          display: block;
          position: relative;
          width: 100%;
          font-size: 12px;
          line-height: 18px;
          margin: 0 0 4px;
        }

        &>.sub {
          display: block;
          position: relative;
          width: 100%;
          white-space: nowrap;
          line-height: 16px;

          & span {
            display: inline-block;
            position: relative;
            margin: 0 10px 0 0;
            padding: 0 10px 0 0;
            font-size: 12px;
            line-height: 16px;
            border-right: solid 1px rgba(0, 0, 0, .16);
            color: #0009;

            &:last-child {
              border: none;
              margin: 0px;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.gap-\[107px\] {
  gap: 107px;
}

.block {
  display: block;
}

.w-\[68\%\] {
  width: 68%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-3 {
  margin-top: 12px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-15 {
  margin-top: 60px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-10 {
  padding-left: 40px;
  padding-right: 40px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.download-btn {
  background: #FF6036;
  color: white;
}

.text-sm {
  font-size: 14px;
}
</style>
